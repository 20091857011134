<template>
    <v-container :class="{'padding-container-top-small': $vuetify.breakpoint.smAndDown }">
      <v-row justify="center">
        <div v-if="loginRequired" class="center">
          <h1>Restricted Area</h1>
          <span>Login using your credentials.</span>
        </div>

        <div v-if="!loginRequired" class="center">
          <h1>Site settings</h1>
          <span>Use links above to access an area to update.</span>
        </div>

        <v-dialog v-model="loginDialog" persistent :fullscreen="$vuetify.breakpoint.smAndDown" max-width="400px">
            <v-card>
                <v-card-title class="card-title">
                    <span v-if="loginDialogType" class="headline primary--text text--lighten-1" :class="{'padding-title': $vuetify.breakpoint.mdAndUp }">Login</span>
                    <span v-if="!loginDialogType" class="headline primary--text text--lighten-1" :class="{'padding-title': $vuetify.breakpoint.mdAndUp }">Register</span>
                </v-card-title>

                <v-form ref="form">
                  <v-card-text>
                      <v-container>
                          <v-row>
                              <v-col cols="12" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                <v-text-field label="Email" v-model="email" prepend-icon="mdi-email" :rules="emailRules">
                                  <v-icon slot="prepend" color="primary lighten-1">mdi-phone</v-icon>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" :class="{'padding-input': $vuetify.breakpoint.smAndDown }">
                                <v-text-field v-model="password"
                                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                  :rules="passwordRules"
                                  :type="showPassword ? 'text' : 'password'"
                                  label="Password"
                                  @click:append="showPassword = !showPassword">
                                  <v-icon slot="prepend" color="primary lighten-1">mdi-lock</v-icon>
                                </v-text-field>
                              </v-col>
                          </v-row>
                      </v-container>
                      
                  </v-card-text>

                  <v-card-actions :class="{ 'padding-action-small': $vuetify.breakpoint.smAndDown, 'padding-action': $vuetify.breakpoint.mdAndUp }">
                      <v-btn color="primary darken-1" text dark @click="close">Close</v-btn>
                      <div class="flex-grow-1"></div>
                      <v-btn color="primary darken-1" text dark @click="actionClick" :loading="loginLoading">{{ action }}</v-btn>
                  </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { MASK } from "@/constants/Masks";
import store from "@/store";

export default {
  directives: {
    mask
  },

  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+\..+/.test(v) || "Email must be valid"
      ],
      passwordRules: [
        v =>
          (!!v && v.length >= 8) ||
          "Field cannot be empty and minimum 8 characters long"
      ],
      phoneMask: MASK.phone
    };
  },

  computed: {
    ...mapGetters(["loginDialogType", "loginDialog", "loginLoading", "user"]),
    action() {
      return !this.loginDialogType ? "Register" : "login";
    },
    loginRequired() {
      return !store.getters.user;
    }
  },

  methods: {
    actionClick() {
      if (this.loginDialogType) {
        this.login();
      } else {
        this.register();
      }
    },
    close() {
      this.$store.dispatch("openLoginDialog", false);
      this.resetForm();
    },
    login() {
      if (this.$refs.form.validate()) {
        const form = {
          email: this.email,
          password: this.password
        };

        this.$store.dispatch("login", form).then(
          response => {
            // eslint-disable-next-line no-console
            console.log("response: ", response);
            this.resetForm();
            this.$router.push({ name: "PropertyDetailsAdmin" }).catch(() => {});
          },
          error => {
            // eslint-disable-next-line no-console
            console.error("response: ", error);
          }
        );
      }
    },
    register() {
      if (this.$refs.form.validate()) {
        const form = {
          email: this.email,
          password: this.password
        };

        this.$store.dispatch("register", form).then(
          response => {
            // eslint-disable-next-line no-console
            console.log("response: ", response);
            this.resetForm();
          },
          error => {
            // eslint-disable-next-line no-console
            console.error("response: ", error);
          }
        );
      }
    },
    resetForm() {
      this.$refs.form.reset();
    }
  }
};
</script>

<style scoped>
.card-title {
  padding: 5px 20px 0px 30px;
}

.padding-title {
  padding: 15px 5px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 5px 30px 20px 30px;
}

.padding-action-small {
  padding: 5px 20px 10px 30px;
}
</style>